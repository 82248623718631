<template>
    <div id="welcome">
        <el-breadcrumb
                separator="/"
                style="padding-left: 10px; padding-bottom: 10px; font-size: 12px"
        >
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>修改密码</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row :gutter="15">
                <!-- 左边部分 -->
                <el-col :span="13">
                    <!-- 用户信息表格 -->
                </el-col>
            </el-row>
            <div slot="header" class="clearfix">
                <span>修改密码</span>
            </div>
            <!-- 功能列表 -->
            <el-row style="margin-top: 10px" :gutter="10">
                <el-form
                        :model="addForm"
                        :label-position="labelPosition"
                        :rules="addFormRules"
                        ref="addFormRef"
                        label-width="80px">
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="旧密码" prop="oldPassword">
                                <el-input v-model="addForm.oldPassword" type="password" maxlength="12"></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" prop="password">
                                <el-input prefix-icon="el-icon-lock"
                                          :type="flag?'text':'password'"
                                          v-model="addForm.password"
                                          maxlength="12">
                                    <i slot="suffix"
                                       :class="[flag?'el-icon-minus':'el-icon-view']"
                                       style="margin-top:8px;font-size:18px;"
                                       autocomplete="auto"
                                       @click="flag=!flag"/>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="确认密码" prop="rePassword">
                                <el-input v-model="addForm.rePassword" type="password" maxlength="12"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <span>
                    <el-button
                          type="primary"
                          @click="resetPassword"
                          :disabled="btnDisabled"
                    >确 定</el-button>

                    <el-button @click="cancel">取 消</el-button>
                </span>
            </el-row>
        </el-card>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            const checkPassword = (rule, value, callback) => {
                const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
                if (!value) {
                    return callback(new Error("密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (passwordReg.test(value)) {
                        callback();
                    } else {
                        callback(new Error("密码必须包含字母和数字的组合"));
                    }
                }, 100);
            };
            const checkRePassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("确认密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (this.addForm.password == value) {
                        callback();
                    } else {
                        callback(new Error("两次密码输入不一致，请确认"));
                    }
                }, 100);
            };
            return {
                labelPosition: "right", //lable对齐方式
                flag: false,
                btnDisabled: false,
                addForm: {
                    oldPassword: "",
                    password: "",
                }, //添加表单
                queryMap: {
                    currentPage: 1,
                    pageSize: 10,
                    name: "",
                    phone: "",
                    status: "",
                    sex: "",
                },
                addFormRules: {
                    oldPassword: [
                        {required: true, message: "请输入旧密码", trigger: "blur"},
                        {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur"}
                    ],
                    password: [
                        {required: true, message: "", validator: checkPassword, trigger: "blur"},
                        {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur"}
                    ],
                    rePassword: [
                        {required: true, message: "", validator: checkRePassword, trigger: "blur"}
                    ],
                }, //添加表单验证规则
            }
        },
        beforeRouteEnter(to, from, next) {
            next(() => {
                window.sessionStorage.setItem('lasterRouter', from.path)
            })
        },
        methods: {

            /**
             * 返回按钮
             */
            cancel() {
                this.$router.push(window.sessionStorage.getItem('lasterRouter'));
            },

            /**
             * 更新密码
             */
            async resetPassword() {
                let that = this;
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        // console.log("修改密码参数：" + JSON.stringify(this.addForm))
                        this.btnDisabled = true;
                        const {data: res} = await this.$http.put("userinfo/password/", this.addForm);
                        this.cancel();
                        setTimeout(function () {
                            that.btnDisabled = false;
                        }, 300)
                        if (res.success) {
                            this.$notify.success({
                                title: '操作成功',
                                message: '用户密码修改成功',
                            });
                            this.addForm = {};
                        } else {
                            return this.$message.error("用户密码修改失败:" + res.data.errorMsg);
                        }
                        this.addDialogVisible = false;
                    }
                });
            },

            /**
             * 加载教师列表
             */
            async getTeacherList() {
                const {data: res} = await this.$http.get("organ/teachers", {
                    params: this.queryMap
                });
                if (!res.success) {
                    return this.$message.error("获取教师列表失败:" + res.data.errorMsg);
                }
                this.total = res.data.total;
                // console.log("教师列表：" + JSON.stringify(res.data))
            },
        },

        created() {
            this.getTeacherList();
        },

        mounted() {
        },
    };
</script>

<style scoped>

</style>
